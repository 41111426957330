import { Help, HelpOutlineOutlined, School } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useMenu } from '../../hooks/use-menu';
import React from 'react';

const SUPPORT_MENU_BUTTON = `support-menu-button`;
const SUPPORT_MENU_NAME = `support-menu`;

interface SupportMenuProps {
    supportPortalLink?: string;
    trainingGuideLink?: string;
}
export function SupportMenu({ supportPortalLink, trainingGuideLink }: SupportMenuProps) {
    const [anchorEl, openMenu, closeMenu, open] = useMenu();

    return (
        <>
            <Menu
                id={SUPPORT_MENU_NAME}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': SUPPORT_MENU_BUTTON,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {supportPortalLink && (
                    <ListItemButton divider component='a' href={supportPortalLink} target='_blank'>
                        <ListItemIcon>
                            <Help />
                        </ListItemIcon>
                        <ListItemText primary='Request Support' />
                    </ListItemButton>
                )}

                {trainingGuideLink && (
                    <ListItemButton divider component='a' href={trainingGuideLink} target='_blank'>
                        <ListItemIcon>
                            <School />
                        </ListItemIcon>
                        <ListItemText primary='View Training Guide' />
                    </ListItemButton>
                )}
            </Menu>
            <IconButton
                size='large'
                id={SUPPORT_MENU_BUTTON}
                aria-controls={open ? SUPPORT_MENU_NAME : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={openMenu}
                sx={{
                    justifySelf: 'end',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    color: 'primary.contrastText',
                }}
            >
                <HelpOutlineOutlined sx={{ color: 'common.white' }} />
            </IconButton>
        </>
    );
}
