import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Dashboard, Folder, List } from '@mui/icons-material';

const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: 'Drive Account',
            env: {
                local: {
                    applicationId: '86b6d7bc-2599-4b92-8a01-20c75fd5ea64',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '86b6d7bc-2599-4b92-8a01-20c75fd5ea64',
                    deployedUrl: 'https://lipari.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: 'c7ef8880-922e-4269-91f6-386dc3d7a8db',
                    deployedUrl: 'https://lipari-account.tools.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: 'a16d756d-74af-47d3-b460-7e20539d5f55',
                    deployedUrl: 'https://lipari-account.tools.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        application: {
            applicationName: 'Cross Selling',
            env: {
                local: {
                    applicationId: '0382fe9c-d9c3-49e0-bdde-06477f96dea8',
                    deployedUrl: 'https://localhost:5003/apps/crosssell',
                },
                dev: {
                    applicationId: '0382fe9c-d9c3-49e0-bdde-06477f96dea8',
                    deployedUrl: 'https://lipari.dev.insight2profit.com/apps/crosssell',
                },
                sandbox: {
                    applicationId: 'd5c45cd7-3ce0-4a37-8fed-d28f3f369a97',
                    deployedUrl: 'https://lipari-crosssell.tools.sandbox.insight2profit.com/apps/crosssell',
                },
                prod: {
                    applicationId: '321720e2-ce1c-412c-89f4-1af19f1ddd95',
                    deployedUrl: 'https://lipari-crosssell.tools.insight2profit.com/apps/crosssell',
                },
            },
        },
        links: [
            {
                title: 'Get Recommendations',
                url: '/product-recommendations',
                icon: <List sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'Saved Recommendations',
                url: '/saved-product-recommendations',
                icon: <Folder sx={{ color: 'primary.main' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.map<DriveAccountNavigation>(({ links, application }) => {
        const envConfig = application.env[localEnvironment];
        return {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links,
            local: applicationId === envConfig.applicationId,
        };
    });
}

export const showHamburger = routeConfig.length > 1;
