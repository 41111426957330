import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface AppLogoProps {
    src: string;
    alt: string;
    maxHeight?: string;
    sx?: SxProps<Theme> | undefined;
}
export function AppLogo({ src, alt, maxHeight, sx }: AppLogoProps) {
    return (
        <Box
            component='img'
            src={src}
            sx={{ ...{ maxHeight: (theme: Theme) => maxHeight || theme.mixins.toolbar.minHeight }, ...sx }}
            alt={alt}
        />
    );
}
