import { AppBar, Theme } from '@mui/material';
import React from 'react';
import { ColoredToolbar } from './colored-toolbar';

interface HeaderProps {
    serviceBaseUrl: string;
}
export function Header({ children, serviceBaseUrl }: React.PropsWithChildren<HeaderProps>) {
    return (
        <AppBar position='static' sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 100 }}>
            <ColoredToolbar serviceBaseUrl={serviceBaseUrl}>{children}</ColoredToolbar>
        </AppBar>
    );
}
