import { Typography } from '@mui/material';

export function Copyright() {
    const year = new Date().getFullYear();
    return (
        <Typography variant='body2' sx={{ color: 'text.secondary', textAlign: 'center' }}>
            © {year} Copyright INSIGHT2PROFIT
        </Typography>
    );
}
