import { AuthorizationService, AxiosInstance, EventService, PowerBiService } from '@price-for-profit/micro-services';
import { AppConfig } from 'shared/types';

export function createServiceContext(appConfig: AppConfig, httpClient: AxiosInstance): app.ServiceContext {
    const eventService = new EventService(httpClient);
    const authorizationService = new AuthorizationService(httpClient);
    const powerBiService = new PowerBiService(httpClient);
    return {
        appConfig,
        eventService,
        authorizationService,
        powerBiService,
    };
}
