import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, MobileStepper } from '@mui/material';
import { UseTourGuideProps, useTourGuide } from './use-tour-guide';
import React from 'react';

const DEFAULT_COLOR = '#00446A';

interface TourGuideModalProps extends UseTourGuideProps {}

export function TourGuideModal(props: TourGuideModalProps) {
    const { steps } = props;
    const {
        hideTourGuide,
        currentHighlightStyle,
        activePositionStyle,
        currentArrow,
        activeStep,
        maxSteps,
        handleBack,
        handleClose,
        handleNext,
    } = useTourGuide(props);
    const open = !hideTourGuide;
    return (
        <>
            {open && <div id={'highlight'} style={currentHighlightStyle}></div>}
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={open}
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    sx: {
                        ...activePositionStyle,
                        position: 'fixed',
                        transition: 'all .5s ease',
                        zIndex: 10000,
                    },
                }}
            >
                <Box
                    id={'highlightArrow'}
                    sx={{
                        ...currentArrow,
                        transition: 'all .5s ease',
                    }}
                ></Box>
                <DialogTitle
                    sx={{ margin: '30px', fontSize: '1.1em', color: steps[activeStep].color || DEFAULT_COLOR }}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    {steps[activeStep].label}
                    {steps[activeStep].imageUrl && (
                        <Box
                            component='img'
                            sx={{
                                width: '50%',
                                height: 'auto',
                                maxHeight: 'none',
                                margin: '30px auto',
                            }}
                            src={steps[activeStep].imageUrl}
                            alt='insight logo'
                        />
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <MobileStepper
                            sx={{ background: 'transparent' }}
                            steps={maxSteps}
                            position='static'
                            activeStep={activeStep}
                            nextButton={
                                <Box display={'flex'}>
                                    <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                                        Back
                                    </Button>
                                    <Divider sx={{ background: DEFAULT_COLOR }} orientation='vertical' flexItem />
                                    {activeStep === maxSteps - 1 ? (
                                        <Button size='small' onClick={handleClose}>
                                            Close
                                        </Button>
                                    ) : (
                                        <Button size='small' onClick={handleNext}>
                                            Next
                                        </Button>
                                    )}
                                </Box>
                            }
                            backButton={
                                <Button size='small' onClick={handleClose} disabled={activeStep === maxSteps - 1}>
                                    Skip tour
                                </Button>
                            }
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
