import {
    AuthenticationResult,
    AuthError,
    EventMessage,
    EventType,
    IPublicClientApplication,
} from '@azure/msal-browser';

import { MsalProvider } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { ClientId, LoginPermissionKey } from '../constants';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from '../startup';
import { useAuthState } from '../stores';
import { InsightB2CClaims } from '../types';
import { getAppPermissions, getUserInfoFromIdClaims } from '../utility';

interface AuthProviderProps {
    clientApp: IPublicClientApplication;
    unauthorizedRoute: string;
    loginPermission: `${ClientId}.${LoginPermissionKey}`;
    clientId: string;
}

export function ProvideAuth({
    children,
    clientApp,
    unauthorizedRoute,
    loginPermission,
    clientId,
}: React.PropsWithChildren<AuthProviderProps>) {
    const navigate = useNavigate();
    const authState = useAuthState();
    const handleError = useErrorHandler();

    React.useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        clientApp.setNavigationClient(navigationClient);
        const accounts = clientApp.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts[0];
            clientApp.setActiveAccount(account);
            const user = getUserInfoFromIdClaims(account?.idTokenClaims as InsightB2CClaims);
            const appPermissions = getAppPermissions(user.insightClaims, clientId);
            if (!appPermissions.includes(loginPermission)) {
                navigate(unauthorizedRoute);
            }
            authState.user.set(user);
        }

        const callbackId = clientApp.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const payload = event.payload as AuthenticationResult;
                    // Verify Insight claims here.
                    const account = payload.account;
                    clientApp.setActiveAccount(account);
                    const user = getUserInfoFromIdClaims(account?.idTokenClaims as InsightB2CClaims);
                    const appPermissions = getAppPermissions(user.insightClaims, clientId);
                    if (!appPermissions.includes(loginPermission)) {
                        navigate(unauthorizedRoute);
                    }
                    authState.user.set(user);
                    break;
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    break;
                case EventType.LOGOUT_END:
                    authState.user.set(null);
                    break;
                case EventType.LOGIN_FAILURE:
                case EventType.ACQUIRE_TOKEN_FAILURE:
                case EventType.LOGOUT_FAILURE:
                    const authError = event.error as AuthError;
                    handleError(authError);
                    break;
                default:
                    break;
            }
        });

        return () => {
            if (callbackId) {
                clientApp.removeEventCallback(callbackId);
            }
        };
    }, [clientApp, history, authState, handleError]);

    return <MsalProvider instance={clientApp}>{children}</MsalProvider>;
}

export const AuthInProgress = () => (
    <Typography sx={{ textAlign: 'center' }} variant='h4'>
        Authorization in progress...
    </Typography>
);
