import React from 'react';

type ServiceProviderProps = {
    context: app.ServiceContext;
};

const serviceContext = React.createContext<app.ServiceContext>({} as app.ServiceContext);

export function ProvideService({ children, context }: React.PropsWithChildren<ServiceProviderProps>) {
    return <serviceContext.Provider value={context}>{children}</serviceContext.Provider>;
}

export function useService() {
    return React.useContext(serviceContext);
}
