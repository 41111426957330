import { Alert, Button, Snackbar } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { register } from './serviceWorkerRegistration';

export function RegisterAppUpdate() {
    const location = useLocation();
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onUpdate = (registration: ServiceWorkerRegistration) => {
        setWaitingWorker(registration.waiting);
    };

    const onInit = (registration: ServiceWorkerRegistration) => {
        setWaitingWorker(registration.waiting);
    };

    const applyUpdate = () => {
        waitingWorker?.addEventListener('statechange', () => {
            if (waitingWorker.state === 'activated') {
                setShowReload(false);
                window.location.reload();
            }
        });
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    };

    React.useEffect(() => {
        navigator.serviceWorker
            .getRegistrations()
            .then(registrations => registrations.forEach(registration => registration.update()));
    }, [location]);

    React.useEffect(() => {
        setShowReload(Boolean(waitingWorker));
    }, [waitingWorker]);

    React.useEffect(() => {
        register({ onUpdate, onInit });
    }, []);
    return (
        <Snackbar open={showReload} onClick={applyUpdate} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert
                action={
                    <Button onClick={applyUpdate} color='secondary' variant='contained'>
                        update
                    </Button>
                }
                severity='info'
            >
                A new version is available!
            </Alert>
        </Snackbar>
    );
}
