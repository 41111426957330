import { AppPermission } from '../constants';

export const getAppPermissions = <T extends string>(InsightClaims: string, clientId: string) => {
    return InsightClaims.split(' ').filter(p => p.startsWith(clientId)) as AppPermission<T>[];
};

export const getUserInfoFromIdClaims = ({
    given_name,
    family_name,
    name,
    email,
    sub,
    InsightClaims,
}: Record<string, string>): app.UserInfo => ({
    firstName: given_name,
    lastName: family_name,
    email,
    displayName: name,
    id: sub,
    insightClaims: InsightClaims,
});
