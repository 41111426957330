import { useMutation } from '@tanstack/react-query';
import { useService } from '../providers';
import { EventData, UserActivityEventPayload } from '@price-for-profit/micro-services';
import { CLIENT_ID } from '../constants';
import { useUser } from '../hooks/use-user';

interface UserActivityData {
    userName?: string;
    scopes: string[];
}
interface UserActivityEvent extends UserActivityData {
    userName?: string;
    scopes: string[];
    userId?: string;
    eventType: 'PageChange' | 'ButtonClick';
    correlationId?: string;
    action: string;
}

export function useUserActivityEvent() {
    const { eventService, appConfig } = useService();
    const user = useUser();

    const mutation = useMutation<EventData, Error, UserActivityEvent>({
        mutationKey: ['user-activity-login'],
        mutationFn: ({ action, eventType, scopes, userId, userName, correlationId }) => {
            const payload: UserActivityEventPayload<UserActivityData> = {
                action,
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
                data: {
                    userName: userName ?? 'no user name',
                    scopes,
                },
                eventType,
                userId: userId ?? user?.id ?? 'no user id',
                timeStamp: new Date(),
                correlationId,
            };
            return eventService.logUserActivity(payload);
        },
        onError: () => {
            console.error('there was a problem capturing user event');
        },
    });

    return mutation;
}
