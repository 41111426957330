import { GridPreProcessEditCellProps, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { formatLongDateString, formatMoney } from './formats';

// Value Formatter
export const moneyValueFormatter: (params: GridValueFormatterParams<any>) => any = ({ value }) => {
    return value || value === 0 ? formatMoney(value) : `${value}`;
};

export const dateFormatter = (params: GridValueFormatterParams<string>) =>
    params.value === null || params.value === undefined ? '' : formatLongDateString(params.value);

export const modifiedByFormatter = (params: GridValueFormatterParams<string>) => {
    if (!params.value) return '';
    if (params.value.indexOf('|MA|') === -1) return params.value;
    return params.value?.substring(0, params.value.indexOf('|MA|'));
};

// Validation
export const PositiveNonZero = (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value <= 0;
    return { ...params.props, error: hasError };
};
